<template>
  <div class="sm:pt-8">
    <v-img
      :src="require('@/assets/images/bg_sector.jpg')"
      class="align-end pa-5 h-screen mt-10 text-left"
      height="500"
      v-if="dataItems.sector.length > 0 || dataItems.service.length > 0">
      <div class="grid items-center justify-items-center">
        <v-col cols="11">
          <v-col cols="12" sm="5" class="text-black pa-0">
            <div
              class="text-xl sm:text-4xl font-bold tracking-wide pb-2"
              v-if="bahasa">
              Lingkungan yang lebih baik dimulai dengan program hebat untuk
              menyelamatkannya.
            </div>
            <div
              class="text-xl sm:text-4xl font-bold tracking-wide pb-2"
              v-else>
              Better environment starts<br />with great programs to<br />save
              it.
            </div>
            <div class="text-base pb-2 mt-10 font-lora mb-10" v-if="bahasa">
              Inilah sektor kami di berbagai area.
            </div>
            <div class="text-base pb-2 mt-10 font-lora mb-10" v-else>
              Here’s our sectors in different areas.
            </div>
          </v-col>
          <v-btn icon @click="$vuetify.goTo('#sector')" class="mt-10 mb-5"
            ><v-icon>south</v-icon></v-btn
          >
        </v-col>
      </div>
    </v-img>
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <template v-else>
      <div
        class="grey lighten-4 grid justify-items-center px-0 py-10"
        id="sector"
        v-if="dataItems.sector.length > 0 || dataItems.service.length > 0">
        <v-col cols="11" class="pa-0">
          <v-row class="px-3" v-if="dataItems.sector.length > 0">
            <v-col cols="12" class="text-left mb-4">
              <span class="font-bold text-3xl" v-if="bahasa"
                >Sektor Lingkungan</span
              >
              <span class="font-bold text-3xl" v-else
                >Environmental Sectors</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="cursor-pointer mb-5 text-left"
              v-for="(item, index) in dataItems.sector"
              :key="index"
              @click="goTo(item.id)">
              <v-row class="px-3">
                <v-col cols="2" class="py-0">
                  <img
                    :src="baseUrlImage + item.image.file_path"
                    @error="
                      $event.target.src =
                        'https://admin.bpdlh.id/assets/empty.png'
                    "
                    width="100%" />
                </v-col>
                <v-col cols="9" class="py-0">
                  <div class="font-bold text-lg py-0">{{ item.name }}</div>
                  <div
                    class="font-lora text-base pt-0"
                    v-html="strLimit(item.desc, 90)"></div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="px-3" v-if="dataItems.service.length > 0">
            <v-col cols="12" class="text-left mb-4 mt-10">
              <span class="font-bold text-3xl" v-if="bahasa"
                >Pelayanan lingkungan</span
              >
              <span class="font-bold text-3xl" v-else
                >Environmental Services</span
              >
            </v-col>
          </v-row>
          <v-row class="mb-10">
            <v-col
              cols="12"
              sm="6"
              v-for="(item, index) in dataItems.service"
              :key="index"
              @click="goTo(item.id)"
              class="cursor-pointer mb-5 text-left">
              <v-row class="px-3">
                <v-col cols="2" class="py-0">
                  <img
                    :src="baseUrlImage + item.image.file_path"
                    @error="
                      $event.target.src =
                        'https://admin.bpdlh.id/assets/empty.png'
                    "
                    width="100%" />
                </v-col>
                <v-col cols="9" class="py-0">
                  <div class="font-bold text-lg py-0">{{ item.name }}</div>
                  <div
                    class="font-lora text-base pt-0"
                    v-html="strLimit(item.desc, 90)"></div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <v-col
        cols="11"
        class="pa-0 mt-10 py-10"
        v-if="dataItems.sector.length === 0 && dataItems.service.length === 0">
        <v-row>
          <v-col cols="12" sm="6">
            <img src="@/assets/images/405.png" width="100%" />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="text-black flex flex-wrap content-center">
            <v-col cols="12" class="text-3xl font-bold pl-0">{{
              bahasa
                ? "Tidak Ada Data Sektor yang Tersedia"
                : "No Sector Data Available"
            }}</v-col>
            <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
              {{ bahasa ? "kembali ke beranda" : "back to home" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoad: false,
      dataItems: {
        sector: [],
        service: [],
      },
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
      lastUpdated: "",
    };
  },
  watch: {
    language() {
      this.getData();
    },
  },
  computed: {
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoad = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(this.baseUrl + "/sector", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            let items = response.data.data.data;
            this.dataItems = {
              sector: items.filter((val) => val.type),
              service: items.filter((val) => !val.type),
            };
            this.lastUpdated = response.data.data.lastUpdate
              ? moment(response.data.data.lastUpdate).format("DD MMMM YYYY")
              : "-";
            this.page = response.data.data.current_page;
            this.pageCount = response.data.data.last_page;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },
    goTo(param) {
      this.$router.push({
        name: "sector-detail",
        params: {
          id: param,
        },
      });
    },
  },
};
</script>
